<template>
    <!-- popup-inner > popup-content -->
    <div id="kb-myclass" class="kb-timetable-survey popup-container is-active" style="display: initial !important; background-color: transparent !important;">
<!--      <div class="kb-main mb-5">-->
<!--        <div class="main-header">&lt;!&ndash; main-header > header-top &ndash;&gt;-->
<!--          <div class="header-top main-component" style="margin-left: 0 !important; padding-left: 0 !important;">&lt;!&ndash;v-if&ndash;&gt;-->
<!--            <div class="header-column">-->
<!--              <router-link :to="{name: 'Main'}" class="page-nav"><i class="icon-kb-nav-arrow"></i><span class="text">메인으로</span></router-link>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="survey-header">
        <h3 v-if="isReady" class="title text-primary text-center">{{dist.crseNm}} 설문</h3>
<!--        <div class="meta">-->
<!--          <span class="text">{{ timestampToDateFormat(dist.bgngDt, 'yyyy.MM.dd.w') }} - {{ timestampToDateFormat(dist.endDt, 'yyyy.MM.dd.w') }}</span>-->
<!--        </div>-->

      </div>
      <div v-if="isReady" class="survey-header mt-3 mb-3">
        <h1 class=" text-md text-left mb-3">[설문안내]</h1>
        <h3 class=" text-md text-left" style="white-space:break-spaces">{{svys[0].svyCn}}</h3>
      </div>

      <main class="kb-main main-center" v-if="pageMsg">
        <div class="min-component">
          <div class="error-page">
            <div class="images">
              <img src="@/assets/lxp/images/common/loading_01.svg" alt=""/>
            </div>
            <h3 class="title">설문</h3>
            <p class="description">
              {{pageMsg}}<br><br>인터넷 브라우저를 종료해주시기 바랍니다.
            </p>
          </div>
        </div>
      </main>

      <div class="survey-container" v-else>
        <template v-for="(svy, index) in svys" :key="index">
          <div v-if="index > 0" class="mt-5 mb-5 kb-border-top-underline" ></div>

          <article v-if="svy.tmtblSvyYn === 'Y'" class="popup-section mt-6">
            <header class="section-header">
              <h4 class="title">강의 정보</h4>
            </header>
            <div class="section-content">
              <div class="kb-table kb-table-striped">
                <table>
                  <colgroup>
                    <col style="width:auto;">
                    <col style="width:250px">
                    <col style="width:250px">
                    <col style="width:250px">
                  </colgroup>
                  <thead>
                  <tr>
                    <th><span class="th-title">강의명</span></th>
                    <th><span class="th-title">챕터명</span></th>
                    <th><span class="th-title">강사</span></th>
                    <th><span class="th-title">일정</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td><p class="td-text">{{ svy.objNm }}</p></td>
                    <td><p class="td-text">{{ svy.chapNm ? svy.chapNm : '-' }}</p></td>
                    <td><p class="td-text">{{ getTutors(svy, '-') }}</p></td>
                    <td><p class="td-text">{{ timestampToDateFormat(svy.objBgngDt, 'yyyy.MM.dd hh:mm') }} ~<br>{{timestampToDateFormat(svy.objEndDt, 'yyyy.MM.dd hh:mm')}}</p></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </article>

          <!-- survey-group -->
          <template v-if="svy.parts && svy.parts.length > 0">
            <section v-for="(part, idx) in svy.parts" class="survey-group" :key="`${index}-${idx}`" style="margin-top: 20px; padding-top: 20px">
              <!-- popup-section:과정 이해도에 대한 설문 -->
              <article v-if="part.svyPartNm || part.svyPartCn" class="popup-section section-info">
                <div class="section-content">
                  <div class="segment-box" style="padding: 22px;">
                    <h4 v-if="part.svyPartNm" class="title" style="margin-bottom: 0px">{{ part.svyPartNm }}</h4>
                  </div>
                </div>
              </article>

              <!-- 1. 해당 과목에 대한 사전 이해도를 선택해주세요. -->
              <template v-for="(question, i) in part.questions" :key="`${index}-${idx}-${i}`">
                <article v-if="question.isShow" class="popup-section section-form" style="margin-top: 25px">
                <header class="section-header" style="padding-left: 5px">
                  <h1>{{ getQuestionNo(svy.parts, idx, i) }}. {{ question.qstnNm }}</h1>
                  <div v-if="question.invalidated" class="header-sub">
                    <i class="icon-warning"></i>
                    <strong class="text text-red">필수 항목입니다</strong>
                  </div>
                </header>
                <div class="section-content">
                  <!-- kb-form-fields -->
                  <div class="kb-form-fields" :class="{'is-invalid': question.invalidated}">
                    <!-- kb-form-row:선택 -->
                    <div v-if="selectTypes.includes(question.qstnKindCdDcd)" class="kb-form-row" style="background-color: white">
                      <div class="">
                        <div class="">
                          <div v-for="(option, j) in question.options" style="padding-left: 20px; padding-bottom: 2px" :key="`${index}-${idx}-${i}-${j}`">
                            <input
                                v-if="question.qstnKindCdDcd === '2020002'"
                                v-model="question.model"
                                type="checkbox"
                                class="kb-form-check-input"
                                :name="`chk-${index}-${idx}-${i}-${j}`"
                                :id="`chk-${index}-${idx}-${i}-${j}`"
                                :value="option.optNo"
                                :disabled="!!question.etc"
                            >
                            <input
                                v-else
                                v-model="question.model"
                                type="radio"
                                class="kb-form-check-input"
                                :name="`chk-${index}-${idx}-${i}`"
                                :id="`chk-${index}-${idx}-${i}-${j}`"
                                :value="option.optNo"
                                :disabled="!!question.etc"
                                @click="chkLink(index, part)"
                            >

                            <label :for="`chk-${index}-${idx}-${i}-${j}`" class="kb-form-check-label"><span class="text">{{ option.optCn }}</span></label>
                          </div>
                        </div>
                      </div>

                      <div v-if="question.etcAnsYn === 'Y'" class="kb-form-column justify-content-end" @click="toggleEtc(question)">
                        <button class="kb-btn-check"><span class="text">{{ question.etc ? '취소' : '기타입력' }}</span></button>
                      </div>
                    </div>

                    <div v-else class="kb-form-row">
                      <div class="kb-form-column">
                        <textarea v-model.trim="question.model" class="kb-form-control"></textarea>
                      </div>
                    </div>

                    <!-- kb-form-row:기타 -->
                    <div v-if="question.etc" class="kb-form-row">
                      <div class="kb-form-column kb-form-column-title">
                        <label class="kb-form-label">
                          <strong class="kb-form-label-text">기타</strong>
                        </label>
                      </div>
                      <div class="kb-form-column column-whole">
                        <input v-model.trim="question.etcAns" type="text" class="kb-form-control" placeholder="직접 입력">
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              </template>
            </section>
          </template>
        </template>
      </div>
      <div v-if="isReady" class="mt-6 text-center">
        <button class="kb-btn kb-btn-primary kb-btn-lg" @click="submitSurveys"><span class="text">설문 제출</span></button>
      </div>
    </div>
</template>
<style>
.kb-timetable-survey .kb-form-fields.is-invalid {border-radius:8px;box-shadow:0 0 0 1px #ff3500}
.kb-timetable-survey .kb-form-row {padding: 8px;}
</style>

<script>

import {surveyTimetableSetup} from "@/assets/js/modules/survey/svy-timetable-setup";

export default {
  name: 'TimetableSurvey',
  setup: surveyTimetableSetup
};
</script>

<style scoped>
.lxp-layout .text-primary {
  font-size: x-large;
}
</style>