import {useStore} from 'vuex';
import {useRoute} from 'vue-router/dist/vue-router';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, ref} from 'vue';
import {getTutors, lrnObjTyCds} from '@/assets/js/modules/learn/learn-common';
import {
  ACT_GET_SVY_TIME_TABLE_LIST,
  ACT_INSERT_SVY_TIME_TABLE_RESULT_LIST,
} from '@/store/modules/survey/survey';
import {
  getItems,
  getResult,
  isSuccess,
  lengthCheck,
  timestampToDateFormat,
  getItem,
} from '@/assets/js/util';
import {ACT_GET_CRSE_DIST_APPLY} from '@/store/modules/course/course';
import {destroyToken} from "@/assets/js/localstorage.service";
import {TOKEN_KEY} from "@/store/modules/auth/auth";

export const surveyTimetableSetup = () => {
  const store = useStore();
  const route = useRoute();

  const {showLoading, hideLoading, showMessage, showConfirm} = useAlert();
  const isReady = ref(false);
  const isLoading = ref(false);

  const distCrseSn = computed(() => route.params.distCrseSn);

  const svys = ref([]);
  const dist = ref({});
  const uniqueLinkQstn = ref([]);
  const excludedLinkQstn = ref([]);
  const isCollective = targetSvy => {
    return targetSvy.lrnObjTyCd === lrnObjTyCds.Collective;
  };

  const getCnVal = (targetSvy) => {
    if (targetSvy.svyCn && targetSvy.svyCn.trim()) {
      return targetSvy.svyCn.trim().split(/\n/g);
    }
    return [];
  };

  const getQuestionNo = (parts, index, idx) => {
    let questionNo = 0;

    for (let i = 0; i < index; i++) {
      questionNo += parts[i].questions.length;
    }
    questionNo += (idx + 1);
    return questionNo;
  };

  showLoading();

  const toggleEtc = (question) => {
    question.etc = !question.etc;
  };

  const validateQuestion = () => {
    let invalidateCount = 0;

    svys.value.forEach(x => {
      if (x.parts && x.parts.length > 0) {
        x.parts.forEach(y => {
          y.questions.forEach(z => {
            // 초기화
            z.invalidated = false;
            if (z.ansEsntlYn === 'Y' && z.isShow) {
              if (z.qstnKindCdDcd === '2020002') {
                if (z.model.length === 0 && z.etcAns === '') {
                  invalidateCount++;
                  z.invalidated = true;
                }
              } else {
                if ((z.model === null || z.model === undefined || z.model ==='') && z.etcAns === '') {
                  invalidateCount++;
                  z.invalidated = true;
                }
              }
            }

          });
        });
      }
    });

    if (invalidateCount > 0) {
      showMessage('설문 필수 항목들이 존재합니다.<br>해당 항목들을 답변해주세요.');
    }

    return invalidateCount === 0;
  };

  const getQuestionAnswer = (question) => {
    if (question.qstnKindCdDcd === '2020002') {
      return question.model.join(',');
    }
    return question.model;
  };

  const getAnswers = parts => {
    const answers = [];
    parts.forEach(x => {
      answers.push(...x.questions.map(y => ({
        svyRsltSn: y.svyRsltSn || 0,
        qstnNo: y.qstnNo,
        ansOptNo: getQuestionAnswer(y),
        etcAns: y.etcAns,
      })));
    });
    return answers;
  };

  const submitSurveys = () => {
    if (isLoading.value) return;
    isLoading.value = true;

    if (validateQuestion()) {
      const params = [];
      svys.value.forEach(x => {
        if (x.parts) {
          params.push({
            distSvySn: x.distSvySn,
            collectiveYn: 'Y',
            results: getAnswers(x.parts),
          });
        }
      });

      showConfirm({
        title: '설문제출',
        text: '설문을 제출하시겠습니까?',
        callback: () => {
          store.dispatch(`survey/${ACT_INSERT_SVY_TIME_TABLE_RESULT_LIST}`,
            params).then(res => {
            if (isSuccess(res)) {
              endPage('설문이 제출되었습니다.');
            } else {
              showMessage(getResult(res).message);
              isLoading.value = false;
            }
          }).catch(e => {
            console.error(e);
            showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            isLoading.value = false;
          });
        },
        closeCallback: () => {
          isLoading.value = false;
        },
      });
    } else {
      isLoading.value = false;
    }
  };

  const pageMsg = ref(null);
  const endPage = (msg) => {
    isReady.value = false;
    pageMsg.value = msg;
    setTimeout(() => {destroyToken(TOKEN_KEY);}, 3000);
  }

  const getSurveyParts = async () => {
    await store.dispatch(`course/${ACT_GET_CRSE_DIST_APPLY}`, distCrseSn.value).
      then(res => {
        // console.log(getItem(res));
        dist.value = getItem(res);
      }).
      catch(() => {
        console.error();
      });
    await store.dispatch(`survey/${ACT_GET_SVY_TIME_TABLE_LIST}`, distCrseSn.value).
      then(res => {
        if (lengthCheck(res)) {
          uniqueLinkQstn.value = [];
          const items = getItems(res);
          items.forEach(x => {
            x.parts.forEach(y => {
              y.questions.forEach(z => {
                z.model = (z.qstnKindCdDcd === '2020002' ? [] : '');
                z.etcAns = '';
                if (z.options) {
                  z.options.forEach(v => {
                    if (v.linkQstnSn) {
                      v.linkQstnSn.split(',').forEach(w => {
                        uniqueLinkQstn.value.push(w);
                      });
                    }
                  });
                }
              });
            });
          });
          svys.value = items;
          hideLoading();
          isReady.value = true;
        } else {
          hideLoading();
          endPage('진행 중인 설문이 없습니다.');
        }

      }).
      catch(() => {
        hideLoading();
      });

    setTimeout(() => {
      initPart();
    }, 100);
  }

  if (distCrseSn.value > 0) {
    getSurveyParts();
  }

  const isLinkQstn = (elem) => {
    if (uniqueLinkQstn.value.filter(x => x === elem.svyQstnSn + '').length > 0) {
      return true;
    }
    return false;
  };

  const initPart = () => {
    svys.value.forEach(p => {
      p.parts.forEach(x => {
        if(x.questions) {
          x.questions.forEach(y => {
            y.isLink = isLinkQstn(y);
            y.isShow = isLinkQstn(y) ? false : true;
          });
        }
      });
    })
    console.log(svys.value);
  }

  const chkLink = async (index, part) => {
    excludedLinkQstn.value = [];
    setTimeout(() => {
      svys.value[index].parts[part.svyPartNo - 1].questions.forEach(x => {
        x.options.forEach(y => {
          if (x.model === y.optNo) {
            const linkQstn = y.linkQstnSn ? y.linkQstnSn.split(',') : [];
            excludedLinkQstn.value.push(...linkQstn);
          }
        });
      });
      // console.log(excludedLinkQstn.value);
    }, 100);

    setTimeout(() => {
      svys.value[index].parts[part.svyPartNo - 1].questions.forEach(y => {
        if (excludedLinkQstn.value.includes(y.svyQstnSn + '')) {
          y.isShow = true;
          y.ansEsntlYn = 'Y';
        } else {
          y.isShow = isLinkQstn(y) ? false : true;
          if (isLinkQstn(y)) {
            y.model = y.qstnKindCdDcd === '2020002' ? [] : '';
          }
        }
      });
    }, 100);
  };

  return {
    isReady,
    dist,
    svys,
    pageMsg,
    getQuestionNo,
    isCollective,
    toggleEtc,
    getTutors,
    submitSurveys,
    timestampToDateFormat,
    getCnVal,
    chkLink,
    selectTypes: ['2020001', '2020002', '2020003', '2020005', '2020006'],
  };
};